import c from "classnames";

import Styles from "./recipeBoxTag.module.scss";

type Props = {
	tag: string;
	inModal?: boolean;
};

export const RecipeBoxTag = ({ tag, inModal = false }: Props) => {
	return (
		<div
			className={c(Styles.tagWrapper, inModal ? Styles.tagWrapperInModal : "")}
		>
			<div className={Styles.triangle}></div>
			<div className={Styles.tag}>{tag}</div>
		</div>
	);
};
