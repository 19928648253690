import { Recipe } from "@/types/data";

import Styles from "./recipeBoxBenefits.module.scss";

export const RecipeBoxBenefits = ({
	benefits,
}: {
	benefits: NonNullable<Recipe["benefits"]>;
}) => {
	return (
		<div className={Styles.recipeBoxBenefits}>
			{benefits.map((benefit) => (
				<div key={benefit} className={Styles.recipeBoxBenefit}>
					{benefit}
				</div>
			))}
		</div>
	);
};
